










import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, User, Global } from '@/store';

@Component({})
export default class VideoGuides extends Vue {
    @Global.Mutation('setPageTitle') setPageTitle;

    links = [
        {text: 'Multiple selection function', value: 'https://www.youtube.com/embed/sW1849XHAiE?ab_channel=TONDOLtd'},
        {text: 'Creating a cabinet schedule', value: 'https://www.youtube.com/embed/GVCgMXgW1C4?ab_channel=TONDOLtd'},
        {text: 'Creating schedule using groups and creating new dimming curve', value: 'https://www.youtube.com/embed/CZ5wI_B1lSs?ab_channel=TONDOLtd'},
        {text: 'Creating a new group', value: 'https://www.youtube.com/embed/gd_PxSzDHBM?ab_channel=TONDOLtd'},
        {text: 'Creating a new filter', value: 'https://www.youtube.com/embed/k0RB9OQb5Ws?ab_channel=TONDOLtd'},
        {text: 'Change device location', value: 'https://www.youtube.com/embed/1t13PQsl_Pw?ab_channel=TONDOLtd'}
    ];

    mounted() {
        this.setPageTitle('Video Guides');
    }

    get video_height(){
        return this.$vuetify.breakpoint.lgAndUp ? 600 : 400;
    }
}
